body {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}
.contact-section {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  direction: rtl;
  overflow: visible;
  padding: 50px;
}

.form-container {
  background-color: #ffffff;
  padding: 30px;
  color: #282828;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  flex: 1;
  z-index: 3;
  overflow: visible;
  z-index: 998;
}
#home-contact-form-container {
  width: 50%;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
}
#home-contact-details-container {
  width: 50%;
  overflow: visible;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#contact-details-title {
  font-size: 5rem;
  text-align: right;
  color: #282828;
  margin: 0px;
  padding: 0;
}
#contact-details-description {
  font-size: 1.6rem;
  text-align: right;
  line-height: 110%;
  overflow: visible;
  color: #282828;
}
#contact-details-social-title {
  margin-top: 40px;
  font-size: 2.7rem;
  color: #282828;
  text-align: center;

}
#contact-details-social-links {
  display: flex;
  flex-direction: row;
}
#contact-details-social-links img {
  height: 80px;
}
#contact-details-social-links a {
  margin: 20px;
}
.image-container-contact {
  flex: 1;
  max-width: 600px;
  max-height: 600px;
  border-radius: 50%;
  margin-right: 150px;
  z-index: -1;
}
.imageS {
  z-index: -1;
}

.image-container img {
  width: 100%;
  object-fit: cover;
}

.titleS {
  color: #282828;
  margin-bottom: 20px;
  font-size: 2em;
  line-height: 100%;
  overflow: visible;
}

.description {
  color: #282828;
  margin-bottom: 40px;
  font-size: 1.2em;
}

.input-container {
  position: relative;
  margin-bottom: 20px;
  overflow: visible;
}
.home-contact-label:hover {
  cursor: text;
}
.inputCon {
  padding: 15px;
  outline: none;
  border: 1px solid #282828;
  background: transparent;
  color: #282828;
  font-size: 1.2em;
  width: 100%;
  overflow: visible;
}

.inputCon:valid + label,
.inputCon:focus + label,
.inputCon:not(:placeholder-shown) + label {
  top: 0;
  font-size: 1em;
  color: #282828;
}

label {
  position: absolute;
  top: 50%; /* center label vertically */
  right: 15px; /* adjust as needed */
  transform: translateY(-50%); /* center label vertically */
  font-size: 1.2em;
  color: #282828d0;
  transition: top 0.2s ease, font-size 0.3s ease, color 0.3s ease;
  background-color: white;
}

.submit-button {
  padding: 20px;
  border: none;
  background-color: #fef000;
  color: #282828;
  border-radius: 20px;
  font-size: 2em;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
}

.submit-button:hover {
  transform: scale(1.05);
  background-color: #01a2e1;
  color: white;
  border-radius: 0px;
}
.formS {
  overflow: visible;
}
@media (max-width: 768px) {
  .contact-section {
    flex-direction: column-reverse;
    padding-right: 0px;
    padding-left: 0px;
    width: 100vw;
    padding: 15px;
    margin: 0;
    margin-bottom: 50px;
  }

  .form-container {
    margin-right: 0;
  }

  .image-container {
    display: none;
  }
  #home-contact-form-container {
    width: 100%;
    overflow: visible;
  }
  #home-contact-details-container {
    width: 100%;
    margin-bottom: 20px;
  }
  #contact-details-title {
    font-size: 3rem;
    text-align: center;
  }
  #contact-details-description {
    font-size: 1.3rem;
    text-align: center;
    line-height: 110%;
    overflow: visible;
  }
}
.success-message {
  background-color: #DFF2BF;
  color: #4F8A10;
  margin-top: 15px;
  text-align: center;
  padding: 10px;
  font-size: 1.2em;
  border-radius: 5px;
}
.tiktok-link {
  position: relative;
  display: inline-block;
}

.tiktok-icon {
  width: 80px;
  height: 80px;
  color: white;
  background-color: #282828; /* Circle background */
  border-radius: 50%; /* Makes it circular */
  padding: 10px;
  box-sizing: border-box;
  overflow: visible;
}

.tiktok-icon path {
  animation: subtle-glitch 1.5s infinite;
  filter: drop-shadow(1px 0px #00f2ea) drop-shadow(-1px 0px #ff0050);
}

@keyframes subtle-glitch {
  0% {
    transform: translate(0, 0);
    filter: drop-shadow(1px 0px #00f2ea) drop-shadow(-1px 0px #ff0050);
  }
  20% {
    transform: translate(0.5px, -0.5px);
    filter: drop-shadow(1.5px -0.5px #ff0050) drop-shadow(-1.5px 0.5px #00f2ea);
  }
  40% {
    transform: translate(-0.5px, 0.5px);
    filter: drop-shadow(0.5px 1px #00f2ea) drop-shadow(-0.5px -1px #ff0050);
  }
  60% {
    transform: translate(0.25px, -0.25px);
    filter: drop-shadow(1px 0.25px #ff0050) drop-shadow(-1px -0.25px #00f2ea);
  }
  100% {
    transform: translate(0, 0);
    filter: drop-shadow(1px 0px #00f2ea) drop-shadow(-1px 0px #ff0050);
  }
}
