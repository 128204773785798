#gallery-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
}

.gallery-title {
  margin-bottom: 1em;
  font-size: 3.5rem;
  font-weight: 700;
  color: #282828;
  text-align: center;
  line-height: 100%;
  overflow: visible;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 1em;
  width: 90vw;
  overflow: hidden;
}

@media (max-width: 600px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .gallery-title {
    font-size: 3rem;

    overflow: visible;
  }
}

.gallery-item {
  position: relative;
  overflow: hidden;
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 1.5s ease, transform 1s ease, box-shadow 300ms ease;
  border-radius: 10px;
}

.gallery-item::before {
  content: "";
  float: left;
  padding-top: 100%;
}

.gallery-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.3s ease-in-out;
}

.gallery-item:hover::after {
  background: rgba(0, 0, 0, 0.3);
}

.gallery-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 500ms ease;
}

.gallery-item:hover .gallery-image {
  transform: scale(1.1);
}
.gallery-item:hover {
  cursor: pointer;
}
.gallery-image-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.5em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 100;
  text-align: center;
  line-height: 130%;
}

.gallery-item:hover .gallery-image-title {
  opacity: 1;
}
