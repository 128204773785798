@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: font;
  src: url("./fonts/SecularOne-Regular.ttf");
  font-display: swap;
}

body, html{
  margin: 0;
  font-family:font;
  font-weight: 200;
  direction: rtl;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
* {
  border: 0px dotted red;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.title {
  position: relative;
  display: inline-block; /* required to give space for the underline */
  align-items: center;
  z-index: 999;
}
.title::after {
  content: "";
  position: absolute;
  left: 50%; /* position the underline at the center of the title */
  bottom: 5%; /* position the underline at the bottom of the title */
  transform: translateX(-50%); /* ensure the underline stays centered */
  width: 40px; /* width of the underline */
  height: 5px; /* thickness of the underline */
  background-color: #01a2e1; /* color of the underline */
}