.typing-animation {
  direction: rtl;
  display: inline-block;
  font-size: 7em;
  font-weight: 700;
  color: #282828;
  text-align: center;
  line-height: 100%;
  margin-bottom: 20px;
}
.typing-animation span:after {
  content: "|";
  margin-left: 5px;
  animation: blink 1s steps(5, start) infinite;
  color: #282828;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}
.color1 {
  color: #e02780;
}
.color2 {
  color: #fef200;
}
.color3 {
  color: #00afee;
}
.color4 {
  color: #282828;
}
@media screen and (max-width: 768px) {
  .typing-animation {
    font-size: 3rem;
  }
}