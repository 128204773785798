.product-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
}

h1 {
  font-size: 3rem;
  margin-bottom: 1em;
  text-align: center;
  width: 100%;
  color: #282828;
}

#product-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  height: fit-content;
  min-height: 500px;
}
.product-images {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image {
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
  object-fit: contain;
}

.thumbnail-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  padding: 1rem 0;
}

.thumbnail {
  width: 100%;  /* Let it occupy the full width of the grid column */
  height: 60px;
  cursor: pointer;
  border: 2px solid transparent;
  filter: grayscale(60%);
  transition: 0.3s ease;
  object-fit: contain;
}
#product-title {
  font-size: 3rem;
  overflow: visible;
  color: #282828;
  line-height: 100%;
  text-align: right;
}
#product-description {
  font-size: 1.2rem;
  line-height: 145%;
  margin-top: 30px;
  overflow: visible;
}

.thumbnail:hover {
  filter: grayscale(0);
}
.thumbnail.active {
  border-color: #282828;
  filter: contrast(110%);
}

#product-images-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
#product-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  height: 100%;
  min-height: 500px;
  flex: 1;
}
#product-details button {
  width: 100%;
  transition: 300ms;
  max-width: 500px;
  padding: 10px;
  border: 2px solid #282828;
  font-size: 1.3rem;
  margin-top: auto;
  overflow: hidden;
  position: relative;
}

#product-add-to-cart::before {
  content: "";
  position: absolute;
  bottom: 0; /* start from bottom */
  left: 0;
  width: 100%;
  height: 0; /* initial height */
  background-color: #282828; /* or any color you want the fill to be */
  transition: height 0.3s ease; /* animate height */
  z-index: -1; /* put it below the text */
}
#product-add-to-cart.in-cart::before {
  height: 100%;
}
#product-add-to-cart.in-cart {
  color: white;
}
#product-gallery-container {
  position: relative;
  margin-top: 100px;
  width: 100vw; /* width is set to 100% of the parent container, not the whole viewport */
  min-height: 140px; /* height is set explicitly */
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-attachment: fixed;
  background-position: center;
}
#product-gallery-container img {
  position: absolute;
  height: 200%; /* As per your original code */
  z-index: 2;
  left: 50px;
  top: -90%;
}
#product-gallery-container div {
  z-index: 2;
  display: flex;
}
#product-gallery-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw; /* pseudo-element will take 100% width of the parent container */
  height: 100%; /* pseudo-element will take 100% height of the parent container */
  background-color: #232323; /* black with 0.3 opacity */
  z-index: 1; /* to make sure it's above the background image */
}
#product-gallery-container p {
  color: white;
  font-size: 1.7rem;
  z-index: 2;
}
#product-gallery-container a {
  z-index: 2;
  background-color: #00afee;
  color: white;
  padding: 10px 25px;
  margin-right: 50px;
  transition: 300ms ease;
  font-size: 1.2rem;
}
#product-gallery-container a:hover {
    transform: translateY(-5px);
}

@media screen and (max-width: 1050px) {
  #product-gallery-container img {
    position: default;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: -50%;
  }
  .product-page-container {
    padding: 2rem;
  }
  #product-gallery-container{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #product-gallery-container div{
    flex-direction: column;
    padding-top: 80px;
  }
  #product-gallery-container a {
    margin-right: 0;
    margin-top: 10px;
  }
}
@media screen and (max-width: 930px) {
  #product-container {
    flex-direction: column;
    height: fit-content;
    width: 100%;
  }
  #product-images-container {
    width: 100%;
  }
  #product-details {
    width: 100%;
    align-items: center;
    min-height: 0;
  }
  #product-title {
    text-align: center;
  }
  .thumbnail-container {
    grid-template-columns: repeat(4, 1fr);
  }
  #product-gallery-container img {
    position: default;
    height: 80%;
    left: 50%;
    transform: translateX(-50%);
    top: -25%;
  }

}
@media (max-width: 768px) {
  .product-details {
    flex-direction: column;
  }
  .description {
    text-align: center;
  }
  #product-add-to-cart {
    margin-top: 20px;
  }
  #product-gallery-container img {
    position: default;
    height: 60%;
    left: 50%;
    transform: translateX(-50%);
    top: -25%;
  }
}
