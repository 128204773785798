.add-product-container {
    padding: 20px;
  }
  
  .add-product-form {
    display: flex;
    flex-direction: column;
  }
  
  .add-product-form input[type="text"] {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .add-product-form #description {
    border: 1px solid rgba(128, 128, 128, 0.5);
    padding: 10px;
    min-height: 150px;
    border-radius: 4px;
  }
  
  .add-product-form button {
    align-self: center;
    padding: 7px 20px;
    color: white;
    font-size: 20px;
  }
  
  .add-product-form button[type="submit"] {
    background-color: #2da0e4;
  }
  .add-product-form button[type="submit"]:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .back-to-category {
    background-color: black;
    color: white;
    padding: 10px;
  }

.fetched-products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}

/* Single fetched product */
.fetched-product {
  border: 1px solid #ccc;
  padding: 16px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Product image */
.fetched-product img {
  max-width: 100%;
  height: auto;
}

/* Delete button */
#edit-product-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: red;
  color: white;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
}

#edit-product-button:hover {
  background: darkred;
}
#transfer-button {
  background-color: green;
  color: white;
  padding: 5px;
}
#delete-product-button {
  background-color: red;
  color: white;
  padding: 5px;
  margin-left: 3px;
}
.edit-product-form {
  position: fixed; /* Fixed position */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
#edit-product-container {
  background-color: white;
  min-height: 500px;
  min-width: 900px;
  position: relative;
}
.cancel-editing {

  background-color: red;
  padding: 5px 25px;
  color: white;
}
.editing-thumbnail-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 300px;
}
#editing-thumbnail-div {
  width: 300px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 300px;
}
#editing-thumbnail-div div {
  max-width: 100px;
}
.editing-thumbnail-container div img{
  max-width: 100px;
}
#editing-form {
  padding: 20px;
  display: flex;
  flex-direction: row;
}
#editing-form input[type=text] {
  border: 2px solid #232323;
  padding: 5px;
  width: 400px;
}
#editing-form button {
  background-color: green;
  color: white;
  padding: 5px 25px;
}
#editing-form textarea {
  border: 2px solid #232323;
  padding: 5px;
  width: 400px;
  min-height: 300px;
}