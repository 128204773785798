.product-card-link {
  text-decoration: none;
  color: inherit;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 997;
}

.product-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 300px;
  overflow: visible;
  background-color: white;
}

.product-card:hover {
  box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  background-color: white;
  border: none;
}

.product-image-container {
  width: 100%;
  height: 60%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-card-icons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 20px;
  overflow: visible;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #282828;
  font-size: 24px;
  transition: color 0.3s ease, transform 0.3s ease;
  outline: none;
}

.icon-button.active {
  animation: fill 0.5s;
}

.heart-icon.active {
  color: red;
}

.cart-icon.active {
  color: #282828;
}

@keyframes fill {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 600px) {
  .product-card {
    width: 90%;
    height: auto;
    margin: 10px;
    padding: 10px;
  }

  .product-image-container {
    height: 150px;
  }
  .product-card:hover {
    box-shadow: none;
    transform: none;
    background-color: none;
    border: 1px solid #ccc;
  }
}

.cart-button {
  position: relative;
  padding: 10px;
  height: 55px;
  overflow: hidden;
  border: 0;
  border-radius: 5px;
  background-color: #282828;
  color: white;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  width: 100%;
}

.cart-button .fa-shopping-cart {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  font-size: 1.5rem;
  transform: translate(-50%, -50%);
}
.cart-button .fa-box {
  position: absolute;
  z-index: 3;

  left: 51%;
  font-size: 1.1rem;
  transform: translate(-50%, -50%);
  color: white;
  transition: 0.3s ease-in-out;
}
.cart-button span {
  display: none;
}
#input-div-product {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  z-index: 1000;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px; /* For spacing between elements */

}

#input-div-product input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

#input-div-product button {
  padding: 10px;
  background-color: #008CBA;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
#input-div-product-link {
  padding: 10px;
  background-color: #008CBA;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  text-align: center;
}
.overlay-product {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 999; /* Ensure this is below the z-index of #input-div-product */
}
.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
}