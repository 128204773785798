#about-page-container {
    background-color: #ffffffc5;
    padding: 20px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .about-content-section {
    max-width: 850px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;
  }
  
  .about-title {
    color: #333333;
    margin-bottom: 15px;
    line-height: 100%;
  }
  
  .about-description,
  .products-services-description {
    color: #555555;
    font-size: 22px;
    margin: 10px 0;
  }
  
  .products-services-title {
    color: #333333;
    font-size: 20px;
    margin-top: 20px;
  }
  #about-form-container {
  width: 100vw;
  background-image: linear-gradient(to left, #00bbff, #0080af);
}
@media screen and (max-width: 769px) {
  .about-title {
    font-size: 35px;
  }
  #about-form-container {
    background-image: linear-gradient(to bottom, #00afee, #0080af);
  }
}
