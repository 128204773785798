.contact-container {
  padding-top: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  text-align: center;
  position: relative;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 175, 238, 0.2) 63%,
    white 50%
  );
}
.contact-container * {
  overflow: visible;
}
#contact-title {
  font-size: 3.5rem;
  color: #282828;
  line-height: 3.5rem;
}

#contact-description {
  color: rgb(70, 70, 70);
  font-size: 20px;
}

#contact-page-form-container {
  max-width: 1200px;
  margin: 20px;
  margin-top: 50px;
  background: white;
  box-shadow: 0px 0px 40px -30px #282828;
  display: flex;
  border-radius: 35px;
  align-items: stretch;
  overflow: visible;
  height: 550px;
  z-index: 4;
}

#contact-form {
  flex: 3;
  padding: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.input-row {
  display: flex;
  width: 100%;

  margin-bottom: 30px;
}

#contact-page-form .input-group {
  position: relative;
}
#contact-page-form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contact-page-form input,
#contact-page-form textarea {
  width: 100%;
  background: none;
  max-height: 150px;
  min-height: 50px;
  outline: none;
  border: none;
  border-bottom: 2px solid gray;
  color: black;
  padding: 10px;
  font-size: 1rem;
  transition: border-bottom 0.3s ease;
}
#contact-page-form textarea {
  top: 0;
  width: 100%;
  background: none;
  max-height: 150px;
  min-height: 50px;
  outline: none;
  border: none;
  border-bottom: 2px solid gray;
  color: black;
  padding: 10px;
  font-size: 1rem;
  overflow: visible;
  transition: border-bottom 0.3s ease;
}
.input-row input:nth-child(1) {
  margin-left: 20px;
}
.input-row input:nth-child(2) {
  margin-right: 20px;
}
#contact-page-form input:valid,
#contact-page-form textarea:valid {
  border-bottom: 2px solid #00afee;
}

#contact-page-form label {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 0.9rem;
  color: gray;
  transition: top 0.3s, font-size 0.3s;
}

#contact-page-form input:focus + label,
#contact-page-form textarea:focus + label {
  top: -20px;
  font-size: 0.8rem;
}

#contact-page-form input:valid + label,
#contact-page-form textarea:valid + label {
  top: -15px;
}

#contact-details-container {
  flex: 2;
  background-image: linear-gradient(to bottom, #494949, #282828);
  border-radius: 20px 0 0 20px;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin: 0px;
  width: 500px;
  text-align: start;
}

#contact-page-form input[type="submit"] {
  cursor: pointer;
  background: #00afee;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
  border: none;
  font-size: 1.3rem;
  align-self: flex-start;
  width: fit-content;
  transition: 0.2s ease;
  margin-top: 20px;
}
#details-title {
  font-size: 2.5rem;
}
#details-description {
  margin-bottom: 40px;
  color: rgb(255, 255, 255, 0.95);
}
.detail-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
}
.detail-item a {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#contact-page-form input[type="submit"]:hover {
  transform: translateY(-5px);
}
@media screen and (max-width: 1350px) {
  #contact-form {
    padding: 50px;
  }
}
@media screen and (max-width: 1200px) {
  #contact-form {
    padding: 50px;
  }
}
@media (max-width: 800px) {
  #contact-page-form-container {
    flex-direction: column;
    max-width: 90vw;
    height: auto;
    margin: 20px;
    align-items: center;
  }
  .input-row {
    flex-direction: column;
    margin: 0;
  }
  #contact-page-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #contact-page-form input[type="submit"] {
    align-self: center;
  }
  #contact-form {
    flex: none;
    width: 100%;
  }
  #details-title {
    font-size: 2.2rem;
  }
  #details-description {
    margin-bottom: 20px;
    text-align: start;
  }
  #contact-details-container {
    width: 100%;
    border-radius: 0 0 20px 20px;
    margin: 0;
    justify-content: center;
    text-align: center;
  }
  .input-row input:nth-child(1) {
    margin-left: 0px;
  }
  .input-row input:nth-child(2) {
    margin-right: 0px;
  }
}
